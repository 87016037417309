import Base from '@/libs/core/Base'
import Vault from '@/libs/core/Vault'

const fields = {
  phone: '',
  password: '',   
}

export default class Auth extends Base {
  constructor () {
    super(fields)
  }

  login () {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields(['phone', 'password'])
        data.phone = data.phone.replace(/[^0-9]+/g, "")
        let response = await this.form.submit('post', 'login', data)
        Vault.keep(response.data)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  logout () {
    Vault.seal()
  }
}