<template>
  <v-card flat>
    <v-card-title>
      <div>
        <h1 class="text-h5 font-weight-bold">Sign in</h1>
        <p class="body-1">Log into your administrator account</p>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="mb-7">
        <vue-tel-input
          :disabled="Boolean(user)"
          v-model="authObj.phone"
          class="outlined rounded-lg"
        ></vue-tel-input>
        <p
          v-if="errors.has('phone')"
          class="ma-0 px-3"
          style="color: #e74c3c; font-size: 12px; position: absolute"
        >
          {{ errors.get('phone') }}
        </p>
      </div>

      <v-text-field
        dense
        outlined
        persistent-hint
        type="password"
        label="Password"
        class="rounded-lg"
        v-model="authObj.password"
        :hint="errors.get('password')"
        :error="errors.has('password')"
        @input="errors.clear('password')"
      ></v-text-field>
    </v-card-text>

    <v-card-actions class="px-4">
      <v-btn
        block
        large
        color="primary"
        class="rounded-lg body-2 font-weight-bold"
        :dark="!loading"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      >
        Log in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Auth from '@/libs/iam/Auth'

export default {
  data() {
    return {
      user: null,
      loading: false,
      authObj: new Auth(),
    }
  },

  computed: {
    errors() {
      return this.authObj.form.errors
    },
  },

  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true
        this.authObj
          .login()
          .then(({ data }) => {
            const client = Object.values(data.administrators)[0]
            vault.keep({ client })
            this.$router.push({ name: 'dashboard' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
